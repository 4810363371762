import { Link } from "gatsby"
import React from "react"

const Projects = ({ features }) => {
  return (
    <section className="projects">
      <div className="container">
        {features && features.length >= 1 ? (
          <div className="row">
            {features.map(feature => {
              return (
                <div key={feature.id} className="col-sm-6 project masonry-item">
                  <div className="image-tile hover-tile text-center">
                    <img
                      alt={feature.title}
                      className="background-image"
                      src={feature.thumbnail.fluid.src || feature.thumbnail}
                    />
                    <div className="hover-state">
                      <Link to={`/gallery/${feature.slug}`}>
                        <h3 className="uppercase mb8">{feature.title}</h3>
                        {feature.subtitle && (
                          <h6 className="uppercase">{feature.subtitle}</h6>
                        )}
                      </Link>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        ) : (
          <div> No featured Galleries </div>
        )}
      </div>
    </section>
  )
}

export default Projects
